body {
  margin: 0;
  font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
/*DatePicker样式*/
.Modal-Portal .datepicker-modal .datepicker .datepicker-navbar {
  border-bottom: 0;
}

.Modal-Portal .datepicker-modal .datepicker {
  border-radius: 1rem 1rem 0 0;
}

.Modal-Portal .datepicker-modal .datepicker .datepicker-caption{
  height: 0;
}

.Modal-Portal .datepicker-modal .datepicker .datepicker-header{
  font-size: 1rem;
  padding: 3% 0 3% 0;
}

.Modal-Portal .datepicker-modal .datepicker .datepicker-navbar-btn{
  padding: 3% 1.5% 3% 1.5%;
}





