@font-face {
    font-family: 'HoneywellSansWeb-Bold';
    src: url('honeywell/HoneywellSansWeb-Bold.woff');
}

@font-face {
    font-family: 'HoneywellSansWeb-Medium';
    src: url('honeywell/HoneywellSansWeb-Medium.woff');
}

@font-face {
    font-family: 'SourceHanSansCN-Bold';
    src: url('sourceHanSans/SourceHanSansCN-Bold.otf');
}

@font-face {
    font-family: 'SourceHanSansCN-Normal';
    src: url('sourceHanSans/SourceHanSansCN-Normal.otf');
}
